@media print {
    .printable-content {
        font-size: 12pt; /* Adjust as needed */
        margin: 0;
        padding: 0;
    }

    /* Hide non-printable elements */
    body * {
        visibility: hidden;
    }

    .printable-content, .printable-content * {
        visibility: visible;
    }

    /* Adjust styles for printed content */
    .printable-content {
        position: absolute;
        left: 0;
        top: 0;
    }
}